// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:722e6c3e-e81d-4ae5-9660-620c05256d8d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aLZZ8Uosz",
    "aws_user_pools_web_client_id": "7970889p418na5v8vkmsa188ob",
    "oauth": {
        "domain": "kichinote-kichinote.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://kichinote.com/,http://localhost:3000/",
        "redirectSignOut": "https://kichinote.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://gt4mkq36bzcwdih4uejtjzolpu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "kichinote-20190818160622-hostingbucket-kichinote",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3cnk2qokxvnye.cloudfront.net",
    "aws_user_files_s3_bucket": "kichinotes-kichinote",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
