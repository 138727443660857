/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNote = `mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    id
    name
    parentNote
    order
    childNote
    childString
    childResource
  }
}
`;
export const updateNote = `mutation UpdateNote($input: UpdateNoteInput!) {
  updateNote(input: $input) {
    id
    name
    parentNote
    order
    childNote
    childString
    childResource
  }
}
`;
export const deleteNote = `mutation DeleteNote($input: DeleteNoteInput!) {
  deleteNote(input: $input) {
    id
    name
    parentNote
    order
    childNote
    childString
    childResource
  }
}
`;
