import React, {useState} from 'react';
import {StyledElement, TextWithLabel, Label, Button, Checkbox} from './style-components';
import {InputBase} from '@material-ui/core';

const Element = (props) => {
  const [text, setText] = useState(props.value);
  const handleChange = event => {
    console.log(event.target.value);
    setText(event.target.value);
  };

  const handleBlur = event => {
    if (props.note.childString !== text) {
      props.updateElement({
        id: props.note.id,
        childString: text
      });
    }
  };

  return (
    <StyledElement>
      <Checkbox
        defaultChecked
        color="default"
        value={props.note.id}
        inputProps={{
          'aria-label': 'checkbox with default color'
        }}
      />
      <TextWithLabel>
        <Label>{props.label}</Label>

        <InputBase
          id="standard-multiline-flexible"
          value={text}
          multiline
          fullWidth={true}
          style={{margin: '0px', borderWidth: '0px', padding: '0px'}}
          onChange={handleChange}
          onBlur={handleBlur}
          margin="none"
        />
      </TextWithLabel>
      <Button onClick={props.openElement}>OPEN</Button>
      <Button onClick={props.deleteElement}>DEL</Button>
      <Button onClick={() => props.openMenu(props.note)}>MENU</Button>
    </StyledElement>
  );
};

export default Element;
