import React, {useState} from 'react';
import styled from '@emotion/styled';
import {Button} from './style-components';
import {InputBase} from '@material-ui/core';

const Modal = styled.div`
  position: fixed;
  width: 100%;
  border: none;
  background-color: rgba(235, 235, 235, 0.85);
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  top: 145px;
  z-index: 2147483644;
  min-height: 50px;
  display: flex;
`;

const ElementModal = (props) => {
  const [text, setText] = useState(props.note.name);
  const handleChange = event => {
    console.log(event.target.value);
    setText(event.target.value);
  };

  const handleCloseMenu = event => {
    const hasChanged = text !== props.note.name;
    props.closeMenu(props.note.id, text, hasChanged);
  };

  return (<Modal>
    <InputBase
      id="standard-multiline-flexible"
      value={text}
      multiline
      fullWidth={true}
      style={{margin: '0px', borderWidth: '0px', padding: '0px'}}
      onChange={handleChange}
      margin="none"
    />
    <Button onClick={handleCloseMenu}>CLOSE</Button>
  </Modal>);
};

export default ElementModal;
