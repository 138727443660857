import React, {useState} from 'react';
import {StyledElement, TextWithLabel, Label, Image, Button, Checkbox} from './style-components';
import {InputBase} from '@material-ui/core';

const Element = (props) => {
  const [text, setText] = useState(props.value);

  const [fileUrl, setFileUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  const handleChange = event => {
    console.log(event.target.value);
    setText(event.target.value);
  };

  const handleBlur = event => {
    alert('BLUR ' + text);
  };

  const handleFileChange = event => {
    const firstFile = event.target.files[0];
    setFileUrl(URL.createObjectURL(firstFile));
    setFile(firstFile);
    setFileName(firstFile.name);
  };

  const handleAddNote = event => {
    event.preventDefault();
    if (fileName !== null) {
      alert('FILE ' + text);
      props.newFile(fileName, file);
      setFileUrl('');
      setFile('');
      setFileName('');
    } else {
      props.createElement(text);
    }
    setText('');
  };

  let content = null;
  if (fileName && fileUrl) {
    content = (<Image alt={fileName} src={fileUrl} />);
  } else {
    content = (
      <TextWithLabel>
        <Label>&nbsp;</Label>

        <InputBase
          id="standard-multiline-flexible"
          value={text}
          multiline
          fullWidth={true}
          style={{margin: '0px', borderWidth: '0px', padding: '0px'}}
          onChange={handleChange}
          margin="none"
        />
      </TextWithLabel>);
  }
  return (
    <StyledElement>
      <Checkbox
        defaultChecked
        color="default"
        value={props.label}
        inputProps={{
          'aria-label': 'checkbox with default color'
        }}
      />
      {content}
      <input type="file" onChange={handleFileChange} />
      <Button onClick={handleAddNote}>ADD</Button>
    </StyledElement>
  );
};

export default Element;
