import React from 'react';

import BottomElement from './bottom-element';
import ObjectElement from './object-element';
import ImageElement from './image-element';
import NewElement from './new-element';

const getViewOfObject = (obj, openElement, updateElement, deleteElement, openMenu) => {
  if (obj.childString) {
    return (<ObjectElement key={obj.id}
      note={obj}
      label={obj.name}
      value={obj.childString}
      openElement={() => openElement(obj.id)}
      updateElement={updateElement}
      deleteElement={() => deleteElement(obj.id)}
      openMenu={openMenu}
    />);
  } else if (obj.childResource) {
    return (<ImageElement key={obj.id}
      note={obj}
      label={obj.name}
      value={obj.childResource}
      deleteElement={() => deleteElement(obj.id)}
      openMenu={openMenu}
    />);
  }
  return null;
};

const Body = (props) => {
  const {notes, createElement, openElement, updateElement, deleteElement, newFile, openMenu} = props;
  return (
    <React.Fragment>
      {notes.map((note) => {
        return getViewOfObject(note, openElement, updateElement, deleteElement, openMenu);
      })}
      <NewElement createElement={createElement} newFile={newFile} />
      <BottomElement />
    </React.Fragment>
  );
};

export default Body;
