import React, {useState, useEffect} from 'react';
import Amplify, {API, graphqlOperation, Storage} from 'aws-amplify';
import awsExports from './aws-exports';
// Auth
import {withAuthenticator} from 'aws-amplify-react';
// GraphQL
import {listNotes} from './graphql/queries';
import {createNote, updateNote, deleteNote} from './graphql/mutations';
import {Page, HeaderBar, FooterBar, Button} from './style-components';
import ElementModal from './element-modal';

// View
import Body from './body';

Amplify.configure(awsExports);

const App = () => {
  const [notes, setNotes] = useState([]);
  const [crumbs, setCrumbs] = useState([]);
  const [modalNote, setModalNote] = useState(null);

  const saveFile = (fileName, file) => {
    Storage.put(fileName, file).then(async (result) => {
      const payload = {
        name: result.key,
        parentNote: 0,
        childResource: result.key
      };
      console.log('SaveFile fileName', fileName, 'payload', JSON.stringify(payload));
      try {
        const {data} = await API.graphql(graphqlOperation(createNote, {input: payload}));
        const newNote = data.createNote;
        setNotes([...notes, newNote]);
        console.log('SaveFile success', notes.length);
      } catch (err) {
        console.error('SaveFile error', err);
      }
    }).catch((error) => {
      console.error('StoragePut error', error);
    });
  };

  useEffect(() => {
    handleListNotes(crumbs);
  }, [crumbs]);

  const handleListNotes = async (crumbs) => {
    let parentNote = '0';
    if (crumbs.length > 0) {
      parentNote = crumbs[crumbs.length - 1];
    }
    try {
      const {data} = await API.graphql(graphqlOperation(listNotes, {
        filter: {
          parentNote: {
            eq: parentNote
          }
        }
      }));
      console.log('ListNotes data', data);
      setNotes(data.listNotes.items);
    } catch (err) {
      console.error('ListNotes error', err);
    }
  };

  const handleOpenElement = async (id) => {
    setCrumbs([...crumbs, id]);
  };

  const handleUpdateElement = async (note) => {
    try {
      const {data} = await API.graphql(graphqlOperation(updateNote, {
        input: {
          id: note.id,
          childString: note.childString
        }
      }));
      console.log('UpdateNote data', data);
      handleListNotes(crumbs);
    } catch (err) {
      console.error('UpdateNote error', err);
    }
  };

  const handleDeleteElement = async (id) => {
    try {
      const {data} = await API.graphql(graphqlOperation(deleteNote, {
        input: {
          id: id
        }
      }));
      console.log('DeleteNote data', data);
      handleListNotes(crumbs);
    } catch (err) {
      console.error('DeleteNote error', err);
    }
  };

  const handleBack = async (id) => {
    if (crumbs.length > 0) {
      setCrumbs(crumbs.slice(0, -1));
    }
  };

  const getNoteId = () => {
    let parentNote = '0';
    if (crumbs.length > 0) {
      parentNote = crumbs[crumbs.length - 1];
    }
    return parentNote;
  };

  const handleCreateElement = async (text) => {
    const payload = {
      name: '-',
      parentNote: getNoteId(),
      childString: text
    };
    try {
      const {data} = await API.graphql(graphqlOperation(createNote, {input: payload}));
      const newNote = data.createNote;
      setNotes([...notes, newNote]);
    } catch (err) {
      console.error(err);
    }
  };

  const openMenu = (note) => {
    setModalNote(note);
  };

  const closeMenu = async (id, text, hasChanged) => {
    if (hasChanged) {
      try {
        const {data} = await API.graphql(graphqlOperation(updateNote, {
          input: {
            id: id,
            name: text
          }
        }));
        console.log('UpdateNote data', data);
        handleListNotes(crumbs);
      } catch (err) {
        console.error('UpdateNote error', err);
      }
    }
    setModalNote(null);
  };

  return (
    <React.Fragment>
      <Page>
        <HeaderBar>
          <Button onClick={handleBack}>BACK</Button> KichiNote {getNoteId()}
        </HeaderBar>
        <Body notes={notes}
          createElement={handleCreateElement}
          openElement={handleOpenElement}
          updateElement={handleUpdateElement}
          deleteElement={handleDeleteElement}
          newFile={saveFile}
          openMenu={openMenu}
        />
      </Page>
      {modalNote && <ElementModal note={modalNote} closeMenu={closeMenu} />}
      <FooterBar>
        Copyright 2019 Kichi
      </FooterBar>
    </React.Fragment>
  );
};

export default withAuthenticator(App, {includeGreetings: true});
