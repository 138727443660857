import React, {useEffect, useState} from 'react';
import {StyledElement, TextWithLabel, Label, Image, Button, Checkbox} from './style-components';
import {Storage} from 'aws-amplify';

const Element = (props) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    Storage.get(props.value)
      .then((result) => {
        setImageUrl(result);
      })
      .catch((error) => {
        console.log('ImageElement error loading image', props.value);
      });
  }, [props.value]);

  return (
    <StyledElement>
      <Checkbox
        defaultChecked
        color="default"
        value={props.label}
        inputProps={{
          'aria-label': 'checkbox with default color'
        }}
      />
      <TextWithLabel>
        <Label>{props.label}</Label>
        {
          imageUrl && <Image src={imageUrl} />
        }
      </TextWithLabel>
      <Button onClick={props.openElement}>SHOW</Button>
      <Button onClick={props.deleteElement}>DEL</Button>
    </StyledElement>
  );
};

export default Element;
