import styled from '@emotion/styled';

const BottomElement = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: auto;
  padding-right: 24px;
  padding-left: 24px;

  &:before {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
    content: '';
    display: table;
  }

  @media (min-width: 744px) {
    max-width: 696px;
  }

  @media (min-width: 1128px) {
    max-width: 1080px;
  }
`;

export default BottomElement;
