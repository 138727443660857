import styled from '@emotion/styled';
import {keyframes} from '@emotion/core';
import Theme from './theme';

export const StyledElement = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: gray;
`;

export const TextWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  min-height: 35px;
  padding-bottom: 2px;
`;

export const Label = styled.div`
  color: gray;
  font-size: 8pt;
  padding-top: ${Theme.topMargin - 1}px;
  padding-bottom: 1px;
`;

export const Image = styled.img`
  width: 40vw;
  height: auto;
`;

export const Button = styled.div`
  display: inline-block;
  color: gray;
  font-size: 10px;
  flex-direction: row;
  border-style: solid;
  border-color: gray;
  border-width: 1px;
  border-radius: 4px;
  width: 50px;
  height: 14px;
  margin-top: ${Theme.topMargin}px;
  margin-left: ${Theme.topMargin}px;
  margin-right: ${Theme.topMargin}px;
  text-align: center;
  vertical-align: top;
`;

export const InputButton = styled.input`
  display: inline-block;
  color: gray;
  font-size: 10px;
  flex-direction: row;
  border-style: solid;
  border-color: gray;
  border-width: 1px;
  border-radius: 4px;
  width: 50px;
  height: 14px;
  margin-top: ${Theme.topMargin}px;
  margin-left: ${Theme.topMargin}px;
  margin-right: ${Theme.topMargin}px;
  text-align: center;
  vertical-align: top;
`;

export const HeaderBar = styled.div`
  position: -webkit-sticky;
  position: sticky;
  transition: transform .2s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  top: 0px;
  z-index: 4;

  background: white;
  display: flex;
  align-items: center;
  height: 44px;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 1;
`;

const slideIn = keyframes`
  0% {
    bottom: 635px !important;
  }
  100% {
    bottom: 0px;
  }
`;

// For animation, use bottom: -145px;
export const FooterBar = styled.div`
  position: fixed;
  width: 100%;
  border: none;
  background-color: rgba(255, 255, 255, 0.85);
  bottom: -145px;
  z-index: 2147483644;
  min-height: 44px;

  display: block;
  animation-name: ${slideIn};
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
`;

export const Page = styled.div`
  min-height: calc(100vh + 65px);
  position: relative;
  z-index: 0;
  padding-bottom: 100px;

@supports (padding: constant(safe-area-inset-bottom)) {
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
}

@supports (padding: env(safe-area-inset-bottom)) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}
`;

export const Checkbox = styled.div`
  display: inline-block;
  color: gray;
  font-size: 10px;
  flex-direction: row;
  border-style: solid;
  border-color: gray;
  border-width: 1px;
  border-radius: 4px;
  width: 14px;
  height: 14px;
  margin-top: ${Theme.topMargin}px;
  margin-left: ${Theme.topMargin}px;
  margin-right: ${Theme.topMargin}px;
  text-align: center;
  vertical-align: top;
`;
